<template>
  <div class="mt-5">
    <b-container>
      <div>
        <h2 class="text-center text-primary font-size-20 font-weight-bold position-relative main-header mb-5">
          {{$t('marketplace.ibbilMarketPlaces')}}
        </h2>
        <p class="about-categories text-black text-justify line-height-2">{{$t('marketplace.marketplaceInfo')}}</p>
      </div>
      <div v-if="!loadingStore">
      <b-row v-if="allCategories.length > 0">
        <b-col class="stores-slide" lg="3" md="6" v-for="(category, key) in allCategories" :key="category.id">
          <router-link v-if="key + 1 != allImageStore.length" :to="{name: 'allMarketplaces' , params: {id:category.slug}}" tag="section" class="iq-border-radius-10 overflow-hidden">
            <div class="h-64 overflow-hidden">
<!--              <img :src="category.cover  ? category.cover : defaultImg" class="w-100 h-100"  :alt="category.name"/>-->
              <img :src="allImageStore[key]" class="w-100 h-100"  :alt="category.name" />
            </div>
            <p class="text-center text-black font-size-20 py-2" >{{category.name}}</p>
          </router-link>
          <router-link v-else to="/stores/merchants/electronic_chip/store-profile/e_ship/" tag="section" class="iq-border-radius-10 overflow-hidden">
            <div class="h-64 overflow-hidden">
              <!--                <img :src="category.cover  ? category.cover : defaultImg" class="w-100 h-100"  :alt="category.name" />-->
              <img :src="allImageStore[key]" class="w-100 h-100"  :alt="category.name" />
            </div>
            <p class="text-center text-black font-size-20 py-2" >{{category.name}}</p>
          </router-link>
        </b-col>
      </b-row>
        <b-row class="justify-content-center align-items-center" v-else>
            <not-found-com/>
        </b-row>
      </div>
      <b-row v-else class="d-flex align-items-center justify-content-center mt-5">
        <b-spinner large type="grow" variant="primary"></b-spinner>
      </b-row>
    </b-container>
  </div>
</template>
<script>
import { core } from '@/config/pluginInit'
import marketPlace from '@/modules/servicePages/marketplace/services/marketplace'
import notFoundCom from '@/modules/servicePages/marketplace/components/notFoundCom'
export default {
  name: 'marketPlaceCategory',
  components: { notFoundCom },
  data () {
    return {
      allImageStore: [require('@/assets/images/PartitionsLogos/store.png'), require('@/assets/images/PartitionsLogos/allaf.png'), require('@/assets/images/PartitionsLogos/montgat.png'), require('@/assets/images/PartitionsLogos/feed.png'), require('@/assets/images/PartitionsLogos/accessories.png'), require('@/assets/images/PartitionsLogos/accessories.png'), require('@/assets/images/PartitionsLogos/electric.png')],
      loadingStore: true,
      allCategories: [],
      defaultImg: require('@/assets/images/ibbil/ibbil-cover.jpg')
    }
  },
  methods: {

    getAllCategories () {
      this.loadingStore = true
      marketPlace.getAllStoresCategories().then(res => {
        this.allCategories = res.data.data
      }).finally(() => {
        this.loadingStore = false
      })
    }
  },
  created () {
    this.getAllCategories()
  },
  mounted () {
    core.index()
  }
}
</script>
<style>
.about-categories{
  font-size: 20px !important;
}
@media (max-width: 992px) {
  .about-categories{
    font-size: 16px !important;
  }
}
</style>
